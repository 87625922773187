export const homeData = {
    title: `Harmony: Empowering Newark Residents with Smart and Inclusive Service Integration`,
    // markdown
    intro: `
##### Smart city services are deeply embedded in modern cities aiming to enhance various aspects of citizens&apos; lives. However, underlying expected or unexpected couplings among services due to complex interactions of social and physical activities are under-explored, which lead to potential conflicts. Using City of Newark in New Jersey as a testbed, this project aims to develop ways of reducing conflicts for ensuring social inclusion and equity of city services to achieve a "harmony" among various services.
`,
    updates: [
        {
            time: "01/2024",
            content: // markdown
                "Our paper got accepted by ICRA’24.",
        }, {
            time: "01/2024",
            content: // markdown
                "Our paper got accepted by WWW’24.",
        }, {
                time: "10/2023",
            content: // markdown
                "Our paper won the [Best Paper Award in CIKM'23](https://www.yyang.site/assets/images/bestpapercikm.jpg).",
        }, {
                time: "08/2023",
            content: // markdown
                "3 papers got accepted by CIKM'23.",
        }, {
            time: "07/2023",
            content: // markdown
                "Tweet on [Presentation of data analyses at Public Management Research Conference](https://twitter.com/RutgersSPAA/status/1683527551640289280?s=20).",
        }, {
            time: "06/2023",
            content: // markdown
                "News on [Multi-Language Trainings for Newark Connect App](https://spaa.newark.rutgers.edu/newsroom/rutgers-tgc-creates-newark-connect-app-trainings).",
        }, {
            time: "05/2023",
            content: // markdown
                "News on [Hosting Digital Dashboard Training for City of Newark Officials](https://spaa.newark.rutgers.edu/newsroom/rutgers-tgc-dashboard-training).",
        }, {
            time: "04/2023",
            content: // markdown
                "Interviews with residents from City of Newark.",
        }, {
            time: "02/2023",
            content: // markdown
                "Focus group interviews with officials from City of Newark.",
        }, {
            time: "07/2022",
            content: // markdown
                "Tweet on [OIT NSF Smart and Connected Cities Working Lunch Meeting](https://twitter.com/RutgersSPAA/status/1552695978414981120?s=20&t=L3HFW497EjOrWQT79LXhCw).",
        }, {
            time: "06/2022",
            content: // markdown
                "Our paper has been nominated for the best paper award by SMARTCOMP’22.",
        }, {
            time: "05/2022",
            content: // markdown
                "Tweet on [Teachning Transparency Panel](https://twitter.com/richardcalland/status/1527521521148764160?s=20&t=A5cUl6RhUkPgkmWhfJnP-Q).",
        }, {
            time: "04/2022",
            content: // markdown
                "Our paper got accepted by SMARTCOMP’22.",
        }, {
            time: "04/2022",
            content: // markdown
                "Our paper got accepted by ICDCS’22.",
        }, {
            time: "01/2022",
            content: // markdown
                "Our paper got accepted by ICRA’22.",
        }, {
            time: "12/2021",
            content: // markdown
                "News on [Newark Tech Week Panel](https://njtechweekly.com/njtw-news-newark-tech-week-women-in-tech-closing-the-digital-divide-more/).",      
        }, {
            time: "11/2021",
            content: // markdown
                "Article [\"Infrastructure law’s digital equity goals are key to smart cities that work for everyone\"](https://theconversation.com/infrastructure-laws-digital-equity-goals-are-key-to-smart-cities-that-work-for-everyone-170068).",      
        }, {
            time: "07/2021",
            content: // markdown
                "Our paper got accepted by EMSOFT’21.",
        }, {
            time: "05/2021",
            content: // markdown
                "Our paper got accepted by TMC’21.",
        }, {
            time: "05/2021",
            content: // markdown
                "Our paper won the [Best Paper Award in ICCPS’21](https://smartcities.rutgers.edu/publication).",
        }, {
            time: "05/2021",
            content:
                "Our project team met with our partners at Newark.",
        }, {
            time: "05/2021",
            content: // markdown
                "Our paper got accepted by SIGKDD’21.",
        }, {
            time: "04/2021",
            content:
                "Our project team met with our partners at Newark.",
        }, {
            time: "03/2021",
            content:
                "Our project team met with our partners at Newark.",
        }, {
            time: "02/2021",
            content:
                "Our project team met with our partners at Newark.",
        }, {
            time: "01/2021",
            content:
                "Article [\"Here’s the Surprising Way Race Matters to Holding Public Officials Accountable\"](https://medium.com/3streams/the-role-of-race-in-public-decisions-to-hold-public-officials-accountable-c4a11ed6a183).",
        }, {
            time: "11/2020",
            content:
                "Our project team met with Newark Chief Operating Officer Natasha Rogers.",
        }, {
            time: "11/2020",
                content:
            "Our project team met with Newark IT Manager Sherronda Carroll and her team.",
        }, {
            time: "10/2020",
                content:
            "Our project team met with Newark Senior Policy Advisor Shoshanna Page.",
        }, {
            time: "10/2020",
            content: // markdown
                "Our paper got accepted by ICDE’21.",
        },{
            time: "09/2020",
                content:
            "Our project team met with Newark City Planning Officer Chris Watson.",
        }, {
            time: "07/2020",
                content:
            "Our project is funded by [NSF](https://www.nsf.gov/awardsearch/showAward?AWD_ID=1952096).",
        }
        ],
    highlights: [
        "3 Years (10/2020 - 09/2023)",
        "$2.3 Million Budget",
        "4 Universities (Rutgers, UVA, SBU, UConn)",
        "7 Faculty (CS, Engineering, Policy)",
        "10+ PhD/master Students",
        "4 Industry and Non-Profit Collaborators",
        "Focused on City of Newark",
        "Working Directly with City Officials",
        "Potential Transferability to Other Cities",
        "Part of NSF Smart and Connected Community Program",
    ],
    // markdown
    objective: `
Develop a socially-aware conflict management theory called Harmony for City Service Integration with five components:

* Service Conflict Specification;
    
* Service Conflict Detection;
    
* Service Conflict Resolution;
    
* Service Conflict Prevention;
    
* Social Intervention for Tech Dissemination and Edu Outreach.
  
Implement Harmony by working with our partners in City of Newark, NJ.`,
    // markdown
    innovation: `
Grounded in social science where new insights were drawn from social data collection to build a consensus on service conflicts.

Materialized with a set of socially-informed technological merits for conflict specification, detection, resolution, and prevention.

Evaluated with both technological and social experiments.

Societal impacts are created through social interventions to inclusively and equitably benefit diverse stakeholders of service conflicts.`,
    videoHtml: // markdown and html
`<iframe width="100%" 
    height="100%" 
    src="https://www.youtube-nocookie.com/embed/TRy0JVVh-So"
    title="YouTube video player" 
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
/>
`,
    imageDirectory: `/homepage_slideshow/`, // parent folder of images in `static` folder
    imageObjects: [
        {
            fileName: `slideshow1.webp`,
            desc: `City Services`,
        },
        {
            fileName: `slideshow2.webp`,
            desc: `ICCPS’21 Best Paper`,
        },
        {
            fileName: `slideshow3.webp`,
            desc: `Team Visiting City of Newark`,
        },
        {
            fileName: `slideshow4.webp`,
            desc: `Meeting with City of Newark`,
        },
        {
            fileName: `slideshow5.webp`,
            desc: `OIT NSF Smart and Connected Cities Working Lunch Meeting`,
        },

    ],
    collaborators: {
        image_directory: "/collaborators/", // path in `static` folder of the project
        collaborator_list: [
            {
                name: "Rutgers University",
                icon_filename: "1_rutgers.svg",
                url: "https://rutgers.edu",
            },
            {
                name: "University of Virginia",
                icon_filename: "2_university_of_virginia.svg",
                url: "https://www.virginia.edu",
            },
            {
                name: "Stony Brook University",
                icon_filename: "3_stony_brook.svg",
                url: "https://www.stonybrook.edu",
            },
            {
                name: "University of Connecticut",
                icon_filename: "4_university_of_connecticut.svg",
                url: "https://uconn.edu",
            },
            {
                name: "City of Newark",
                icon_filename: "5_city_of_newark.svg",
                url: "https://www.newarknj.gov",
            },
            {
                name: "National Science Foundation",
                icon_filename: "6_nsf.svg",
                url: "https://www.nsf.gov/awardsearch/showAward?AWD_ID=1952096",
            },
        ],
    }
};